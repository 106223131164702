// Vendored from https://raw.githubusercontent.com/twbs/bootstrap-sass/master/assets/stylesheets/bootstrap/_forms.scss
// so that we can slowly remove rules from this bit-by-bit and ultimately get rid of the dependency entirely

// Normalize form controls
input[type="file"] {
  display: block;
}

// Form groups
.form-group {
  margin-bottom: $form-group-margin-bottom;
}

// Feedback states
.has-error {
  @include form-control-validation($state-danger-text, $state-danger-text, $state-danger-bg);
}

// Help text
.help-block {
  display: block; // account for any element using help-block
  margin-top: 5px;
  margin-bottom: 10px;
  color: lighten($text-color, 25%); // lighten the text some for contrast
}
